
import {
  defineComponent,
  inject,
  ref,
  reactive,
  Ref,
  onMounted,
  nextTick,
  watch,
  computed,
} from "vue";
import Tags from "@hd2/common/src/components/Tags.vue";
import PhoneNumberComponent from "@hd2/common/src/components/PhoneNumber.vue";
import {
  asyncForEach,
  isValidPesel,
  getDateFromPesel,
} from "@hd2/common/src/utils";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { each, map } from "lodash";
import { useRouter } from "vue-router";
import moment, { Moment } from "moment";
import {
  PatientType,
  RuleObjectExt,
  IdentificationDocument,
  Order,
  UserProfile,
  Patient,
  OrderPersonalModel,
  OrderExt,
  IdType,
} from "../../types";
import { PhoneNumberListItem, PhoneNumber } from "@hd2/common/types";
import phonePrefixes from "@hd2/common/src/utils/phonePrefixes.json";
import { actions } from "../utils/const";
import { usePermissions } from "../composable/usePermissions";
import { Form } from "ant-design-vue";

const useForm = Form.useForm;

interface PatientData {
  model: Omit<Patient, "birthdate"> & { birthdate: Moment | null };
  idType: Set<IdType>;
  rules: Record<string, Array<RuleObjectExt>>;
}

interface IdTypeOption {
  id: IdType;
  name: string;
  disabled: boolean;
}

const phonePrefixOptions: Record<string, PhoneNumberListItem> = phonePrefixes;

export const OrderPersonalComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Tags,
    "phone-number": PhoneNumberComponent,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;

    const appType = store.state.runtimeConfig.type;
    const { hasPermission } = usePermissions();

    const setDefaultAddress: Ref<boolean> = ref(
      store.state.order.setDefaultAddress
    );

    const loading: Ref<boolean> = ref(false);
    const valid: Ref<Array<boolean>> = ref([false, false]);
    const userData: Ref<UserProfile> = ref({
      dateOfBirth: "",
      firstName: "",
      id: "0",
      lastName: "",
      pesel: "",
      providerId: "",
      termsAgreement: true,
      address: {
        city: "",
        postCode: "",
        streetName: "",
        streetNumber: "",
        flatNumber: "",
      },
      email: "",
      phoneNumber: "",
      phoneNumberPrefix: "48",
      nfzActive: false,
      popups: {
        makeDeclaration: false,
        changeDeclarationFacility: false,
      },
    });
    const patients: Ref<Array<PatientData>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsFormTemplate: Ref<Array<any>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsForm: Ref<Array<any>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsFormWrapper: Ref<any> = ref();
    const idTypeOptions: Ref<Array<IdTypeOption>> = ref([
      {
        id: "PESEL" as IdType,
        name: t("ORDER_PERSONAL.PATIENT.HAS_ID_YES"),
        disabled: !hasPermission("ORDER_CHANGE_ID") || store.getters.isForNfz,
      },
      {
        id: "OTHER" as IdType,
        name: t("ORDER_PERSONAL.PATIENT.HAS_ID_NO"),
        disabled: !hasPermission("ORDER_CHANGE_ID") || store.getters.isForNfz,
      },
    ]);

    const model: OrderPersonalModel = reactive({
      address: {
        city: "",
        postCode: "",
        streetName: "",
        flatNumber: "",
        streetNumber: "",
        country: "",
        additionalInfo: "",
      },
      email: "",
      phoneNumber: {
        number: "",
        prefix: "",
        pattern: "",
      },
    });

    const rules: Record<string, Array<RuleObjectExt>> = {
      "address.city": [
        {
          validator: () => {
            return new Promise((resolve, reject) => {
              if (model.address.city.length === 0) {
                reject(t("ORDER_PERSONAL.APPOINTMENT_INFO.CITY_REQUIRED"));
              }
              resolve();
            });
          },
          trigger: "change",
          required: true,
        },
      ],
      "address.postCode": [
        {
          validator: () => {
            return new Promise((resolve, reject) => {
              if (model.address.postCode.length === 0) {
                reject(t("ORDER_PERSONAL.APPOINTMENT_INFO.POST_CODE_REQUIRED"));
              }
              resolve();
            });
          },
          trigger: "change",
          required: true,
        },
      ],
      "address.streetName": [
        {
          validator: () => {
            return new Promise((resolve, reject) => {
              if (model.address.streetName.length === 0) {
                reject(
                  t("ORDER_PERSONAL.APPOINTMENT_INFO.STREET_NAME_REQUIRED")
                );
              }
              resolve();
            });
          },
          trigger: "change",
          required: true,
        },
      ],
      "address.streetNumber": [
        {
          validator: () => {
            return new Promise((resolve, reject) => {
              if (model.address.streetNumber.length === 0) {
                reject(
                  t("ORDER_PERSONAL.APPOINTMENT_INFO.STREET_NUMBER_REQUIRED")
                );
              }
              resolve();
            });
          },
          trigger: "change",
          required: true,
        },
      ],
      email: [
        {
          type: "email",
          required: true,
          trigger: "change",
          message: t("ORDER_PERSONAL.APPOINTMENT_INFO.EMAIL_REQUIRED"),
        },
      ],
      phoneNumber: [
        {
          validator: (rule, value: PhoneNumber) => {
            return new Promise((resolve, reject) => {
              if (!value.number) {
                reject(
                  t("ORDER_PERSONAL.APPOINTMENT_INFO.PHONE_NUMBER_REQUIRED")
                );
              } else if (!value.number.match(value.pattern)) {
                reject(
                  t("ORDER_PERSONAL.APPOINTMENT_INFO.PHONE_NUMBER_INVALID")
                );
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const appointmentInfoForm: any = useForm(model, rules);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const appointmentInfoFormTemplate: Ref<any> = ref();
    each(store.state.order.patients, (value, index) => {
      patients.value.push({
        idType: new Set([
          value.identificationDocument.type === "PESEL"
            ? "PESEL"
            : ("OTHER" as IdType),
        ]),
        model: {
          type: value.type as PatientType,
          age: value.age,
          firstName: value.firstName,
          lastName: value.lastName,
          identificationDocument: value.identificationDocument,
          birthdate: value.birthdate ? moment(value.birthdate) : null,
          predefinedSymptoms: new Set(),
          otherSymptoms: "",
        },
        rules: {
          firstName: [
            {
              options: {
                index,
              },
              validator: (rule, value: Patient["firstName"]) => {
                return new Promise((resolve, reject) => {
                  if (!value) {
                    reject(t("ORDER_PERSONAL.PATIENT.FIRST_NAME_REQUIRED"));
                  } else {
                    resolve();
                  }
                });
              },
              trigger: "change",
            },
          ],
          lastName: [
            {
              options: {
                index,
              },
              validator: (rule, value: Patient["lastName"]) => {
                return new Promise((resolve, reject) => {
                  if (!value) {
                    reject(t("ORDER_PERSONAL.PATIENT.LAST_NAME_REQUIRED"));
                  } else {
                    resolve();
                  }
                });
              },
              trigger: "change",
            },
          ],
          "identificationDocument.number": [
            {
              options: {
                index,
              },
              validator: (rule: RuleObjectExt) => {
                return new Promise((resolve, reject) => {
                  if (
                    !patients.value[rule.options?.index].model
                      .identificationDocument.number
                  ) {
                    reject(t("ORDER_PERSONAL.PATIENT.OTHER_ID_REQUIRED"));
                  } else {
                    if (
                      patients.value[rule.options?.index].idType.has("PESEL")
                    ) {
                      if (
                        !isValidPesel(
                          patients.value[rule.options?.index].model
                            .identificationDocument.number
                        )
                      ) {
                        reject(t("ORDER_PERSONAL.PATIENT.PESEL_INVALID"));
                      }
                    }
                  }
                  resolve();
                });
              },
              trigger: "change",
            },
          ],
          birthdate: [
            {
              options: {
                index,
              },
              validator: (rule: RuleObjectExt, value: Patient["birthdate"]) => {
                return new Promise((resolve, reject) => {
                  if (patients.value[rule.options?.index].idType.has("OTHER")) {
                    if (!value) {
                      reject(t("ORDER_PERSONAL.PATIENT.BIRTHDATE_REQUIRED"));
                    }
                  }
                  resolve();
                });
              },
              trigger: "change",
            },
          ],
        },
      });
    });

    if (store.state.order.address) {
      model.address = store.state.order.address;
      model.email = store.state.order.email;
      model.phoneNumber = store.state.order.phoneNumber;
      valid.value = [true, true];
    }

    const onPeselChange = (patient: PatientData, pesel: string) => {
      if (isValidPesel(pesel)) {
        patient.model.birthdate = moment(getDateFromPesel(pesel));
      } else {
        patient.model.birthdate = null;
      }
    };

    each(patients.value, (patient) => {
      patientsForm.value.push(useForm(patient.model, patient.rules));
    });

    const identificationDocumentTypes: Ref<Array<IdentificationDocument>> = ref(
      []
    );

    const refreshSlot = async (id: Order["id"]) => {
      return await http.post("patient-portal/api/slot/extend", {
        id,
      });
    };

    const submit = async () => {
      const order: OrderExt = {
        ...store.state.order,
        ...model,
        setDefaultAddress: setDefaultAddress.value,
      };
      order.patients = map(order.patients, (patient, index) => {
        return {
          ...patient,
          ...patients.value[index].model,
          birthdate: patients.value[index].model.birthdate?.toISOString() ?? "",
          predefinedSymptoms:
            Array.from(patients.value[index].model.predefinedSymptoms) ?? [],
        };
      });

      store.commit("setOrder", order);
      router.push({ name: "OrderSymptoms", params: { id: props.id } });
    };

    const onPhoneNumberChange = () => {
      appointmentInfoForm.validate("phoneNumber");
    };

    const checkForm = async (name: string) => {
      switch (name) {
        case "appointmentInfoForm": {
          try {
            await appointmentInfoForm.validate();
            valid.value[1] = true;
          } catch {
            valid.value[1] = false;
          }
          break;
        }
        case "patientsFrom": {
          let validCount = 0;
          asyncForEach(patientsForm.value, async (form, index: number) => {
            try {
              await form.validate();
              validCount++;
            } catch {
              return Promise.resolve();
            } finally {
              if (index === patients.value.length - 1) {
                if (validCount === patients.value.length) {
                  valid.value[0] = true;
                } else {
                  valid.value[0] = false;
                }
              }
            }
          });
        }
      }
    };

    const onIdTypeChange = (
      patient: PatientData,
      idType: PatientData["idType"]
    ) => {
      if (idType.has("PESEL")) {
        patient.model.identificationDocument = {
          number: "",
          type: "PESEL",
        };
        onPeselChange(patient, patient.model.identificationDocument.number);
      } else if (idType.has("OTHER")) {
        patient.model.identificationDocument = {
          number: "",
          type: identificationDocumentTypes.value[0].type,
        };
      }
    };

    watch(
      patients,
      () => {
        nextTick(() => {
          checkForm("patientsFrom");
        });
      },
      { deep: true }
    );
    watch(
      model,
      () => {
        checkForm("appointmentInfoForm");
      },
      { deep: true }
    );

    onMounted(async () => {
      loading.value = true;

      Promise.allSettled(
        [
          refreshSlot(props.id),
          http.get(`visit-api/anonymous/document-type`),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ].map((p: Promise<any>, index: number) => {
          if (index === 0) {
            p.catch(() => {
              store.commit("setOrder", {});
              notification.open({
                message: t("ERROR.4865"),
                class: "error",
              });
              router.push({ name: "MakeAppointment" });
            });
          } else if (index === 1) {
            p.then((res) => {
              identificationDocumentTypes.value = res.data;
            }).catch(() => {
              notification.open({
                message: t("ERROR.4514"),
                class: "error",
              });
            });
          }
          return p;
        })
      );

      try {
        if (!store.state.order.address.city) {
          userData.value = await http
            .get(`patient-portal/api/patient-profile`)
            .then((res) => res.data);
          if (userData.value.address) {
            model.address = {
              ...userData.value.address,
              additionalInfo: "",
              country: store.state.order.country.name,
            };
          } else {
            model.address.country = store.state.order.country.name;
          }
          model.phoneNumber = {
            number: userData.value.phoneNumber,
            prefix: `+${userData.value.phoneNumberPrefix}`,
            pattern:
              phonePrefixOptions[`+${userData.value.phoneNumberPrefix}`]
                .pattern,
          };
          model.email = userData.value.email;
          patients.value[0].model.firstName = userData.value.firstName;
          patients.value[0].model.lastName = userData.value.lastName;
          patients.value[0].model.identificationDocument.number =
            userData.value.pesel;

          onPeselChange(
            patients.value[0],
            patients.value[0].model.identificationDocument.number
          );
        }
      } catch {
        notification.open({
          message: t("ERROR.4212"),
          class: "error",
        });
      } finally {
        loading.value = false;
        nextTick(() => {
          if (store.state.order.patients.length === 1) {
            appointmentInfoFormTemplate.value.$el.parentNode.removeChild(
              appointmentInfoFormTemplate.value.$el
            );
            patientsFormWrapper.value.appendChild(
              appointmentInfoFormTemplate.value.$el
            );
          }
        });
      }
    });

    return {
      store,
      t,
      patients,
      model,
      patientsFormTemplate,
      patientsFormWrapper,
      idTypeOptions,
      rules,
      appointmentInfoFormTemplate,
      valid,
      loading,
      identificationDocumentTypes,
      submit,
      hasPermission,
      actions,
      onPhoneNumberChange,
      onPeselChange,
      onIdTypeChange,
      setDefaultAddress,
      translationType: computed(() =>
        ["WELBI"].includes(appType) ? appType : "GENERAL"
      ),
      forNfz: computed(() => store.getters.isForNfz),
    };
  },
});

export default OrderPersonalComponent;
